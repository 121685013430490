<template>
  <div class="info" v-if="props.data">
    <BrandDesc
      :info="props.data.description"
      :nm="props.data.name"
      :nmalt="props.data.name"
      :items_photo="props.data.items_photo"
      />

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('tents_tab_info_awards')"
      :showmore="$t('breeder_view_show_all_diaries')"
      :list="props.data.items_diary.filter(b => {return b.group_name === 'win'})"
      :cnt="props.data.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
    />

    <div class="cpm" v-if="props.data?.items_diary?.length"></div>

    <BrandProductBoxItems
      :title="$t('universal_plurals_products2')"
      :showmore="$t('tents_tab_info_tents_show_all')"
      :products="props.data.items_product"
      :link="$route.path + '/products'"
      :brand-id="props.data.id"
      :category="props.data.category"
    />

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('tents_tab_info_popular_diaries')"
      :showmore="$t('breeder_view_show_all_diaries')"
      :list="props.data.items_diary.filter(b => {return b.group_name === 'top'})"
      :cnt="props.data.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
    />

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('tents_tab_info_new_diaries')"
      :showmore="$t('breeder_view_show_all_diaries')"
      :list="props.data.items_diary.filter(b => {return b.group_name === 'new'})"
      :cnt="props.data.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
    />

    <div class="cpm" v-if="props.data?.items_diary?.length"></div>

    <BrandReviewItems
      :title="$t('tents_tab_info_latest_reviews')"
      :showmore="$t('tents_tab_info_latest_reviews_show_all')"
      :list="props.data.items_review"
      :cnt="props.data.item_stat?.cnt_reviews"
    />

    <BrandQuestionItems
      :title="$t('breeder_view_info_grow_questions')"
      :showmore="$t('breeder_view_info_grow_questions_all')"
      :list="props.data.items_problem"
      :cnt="props.data.item_stat?.cnt_problem"
    />

    <div class="cpm" v-if="props.data?.items_review?.length"></div>

    <BrandSimilarBrands
      :title="$t('tents_view_info_sidebar_title')"
      :list="props.data.items_similar_brand"
    />

    <CommentWidget v-if="data && props.data.id"
      :title="$t('tents_latest_latest_comments')"
      :type="'brand'"
      :content="props.data.id"
    />
  </div>
</template>


<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

</script>

<style scoped>
.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

</style>
